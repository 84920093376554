import { Controller } from "@hotwired/stimulus"
import EasyMDE from 'easymde';

export default class extends Controller {
    static get targets() {
        return ['itemId'];
    }
    connect() {
        const easyMDE = new EasyMDE({
            autoDownloadFontAwesome: true,
            previewImagesInEditor: true,
            nativeSpellcheck: true,
            spellChecker: false,
            autosave: {
            enabled: true,
            delay: 1000,
            uniqueId: `mde-autosave-${this.itemIdTarget.value}`,
            text: "Sauvegarde automatique : ",
            timeFormat: {
                locale: "fr-FR",
            }
            },
            element: this.element
        });
    }
}
