// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
// Entry point for the build script in your package.json

import Rails from "@rails/ujs"
Rails.start()
import "@hotwired/turbo-rails"

import intlTelInput from 'intl-tel-input';

import "./controllers"
import "@popperjs/core/dist/cjs/popper";
import * as bootstrap from "bootstrap"
import $ from "jquery";
import lazysizes from 'lazysizes';
lazysizes.init();
window.$ = $;
window.jQuery = $;

import Swal from 'sweetalert2';
window.Swal = Swal;
import Toastify from 'toastify-js';
window.Toastify = Toastify; 
import Lightbox from 'bs5-lightbox';
window.Lightbox = Lightbox;
document.querySelectorAll('[data-lightbox]').forEach(el => el.addEventListener('click', Lightbox.initialize));
window.Chart = require('chart.js');
window.bootstrap = bootstrap;

import "bootstrap-table";
import 'bootstrap-table/dist/extensions/cookie/bootstrap-table-cookie';
import 'bootstrap-table/dist/extensions/print/bootstrap-table-print';
import 'bootstrap-table/dist/extensions/export/bootstrap-table-export';
import 'bootstrap-table/dist/extensions/filter-control/bootstrap-table-filter-control'; 
import 'bootstrap-table/dist/locale/bootstrap-table-fr-CH'; 
import 'multiple-select/dist/multiple-select.min';

require('tableexport.jquery.plugin')     

window.jspdf = require('jspdf');
window.WOW = require('wowjs').WOW;

window.intlTelInput = intlTelInput;

document.addEventListener('turbo:load', function () {

    
    $('#loader').delay(3000).hide();    

    $('#sidebarCollapse').on('click', function () {
        $('#sidebar, main, #sidebarCollapse').toggleClass('active');
        $('.collapse.in').toggleClass('in');
        $('#overlay-sidebar').toggleClass('show');
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
        $('#overlay-sidebar').on('click', function () {
            $('#overlay-sidebar').removeClass('show');
            $('#sidebar, main, #sidebarCollapse').removeClass('active');
        });
    }); 
    //SUPPRIMER
    $(document).on('click', "[data-swal='true']", function( e ) {
        e.preventDefault()  
        e.stopImmediatePropagation();   
        Swal.fire({
            title: 'Êtes-vous sûr ?',
            html: $(this).data("description"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Annuler'
        }).then((result) => {
            if (result.isConfirmed) {
                Rails.ajax({
                    type: ($(this).data("methode") ? $(this).data("methode") : "DELETE"),
                    url: $(this).data("url")
                })
            }
        })
    });

    const input = document.querySelector('[type="tel"]');
    if(input) {
        intlTelInput(input, {
            // any initialisation options go here
            separateDialCode: false,
            preferredCountries: ["ch", "fr", "de", "it"]
        });
    }

    new WOW({live: false}).init()

    const el = document.getElementById('menu_1');
    if (el !== null) {
        var myCollapsible = el
        myCollapsible.addEventListener('show.bs.collapse', function () {
            myCollapsible.classList.toggle("is_collapsed");
        })
        myCollapsible.addEventListener('hide.bs.collapse', function () {
            myCollapsible.classList.toggle("is_collapsed");
        })

        var myCollapsible2 = document.getElementById('families_menu')
        myCollapsible2.addEventListener('show.bs.collapse', function () {
            myCollapsible2.classList.toggle("is_collapsed");
        })
        myCollapsible2.addEventListener('hide.bs.collapse', function () {
            myCollapsible2.classList.toggle("is_collapsed");
        })

        var myCollapsible3 = document.getElementById('observations_menu')
        myCollapsible3.addEventListener('show.bs.collapse', function () {
            myCollapsible3.classList.toggle("is_collapsed");
        })
        myCollapsible3.addEventListener('hide.bs.collapse', function () {
            myCollapsible3.classList.toggle("is_collapsed");
        })
    }

    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
    })
    
    document.querySelectorAll('[data-trigger]').forEach(function(everyelement){
        
        let offcanvas_id = everyelement.getAttribute('data-trigger');
        
        everyelement.addEventListener('click', function (e) {
            e.preventDefault();
            $('.screen-darken').toggleClass('active');
            $('.mobile-offcanvas').toggleClass('show');
            $('body').toggleClass('offcanvas-active');
        });
    });

    document.querySelectorAll('.btn-close').forEach(function(everybutton){
        
        everybutton.addEventListener('click', function (e) {
            e.preventDefault();
            $('.screen-darken').toggleClass('active');
            $('.mobile-offcanvas').toggleClass('show');
            $('body').toggleClass('offcanvas-active');
        });
    });
});